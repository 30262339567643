import styled from '@emotion/styled'
import { PayTableContainer } from 'assets/images';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${PayTableContainer});
  background-repeat: no-repeat;
  background-size: 350px 141px;
  width: 350px;
  height: 145px;
  font-family: Roboto;
  color: #ffffff;
  font-size: 1.8em;
  user-select: none;
  position: absolute;
  margin-top: -75px;
  z-index: 3;
  grid-gap: 5px;

  .green {
    color: #aaf06c;
  }



  .medium-font-size {
    text-align: center;
    /* font-size: 1.2rem; */
    line-height: 1.2;
  }

  .red {
    color: red;
    text-align: center;
    line-height: 1.2;
  }
`;
